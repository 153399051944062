export default {
  paths: [
    {
      path: 'M147.5,358.2v78.1h-7.9v-78.1H115v-7.3h57.2v7.3H147.5z',
      styleClass: 'st1'
    },
    {
      path:
        'M204.6,383.1c-2-1.4-4.5-2.1-6.9-2c-1.9,0-3.7,0.4-5.4,1.2c-1.5,0.8-2.9,1.8-4.1,3.1c-1.2,1.4-2.2,2.9-3,4.6	c-0.8,1.7-1.5,3.5-2,5.4c-0.5,1.9-0.9,3.7-1.1,5.7c-0.2,1.8-0.3,3.6-0.3,5.4v29.8h-7.6v-61h7.6v13h0.2c0.7-2.1,1.6-4.1,2.7-6	c1-1.7,2.3-3.2,3.8-4.5c1.4-1.2,3-2.1,4.7-2.8c1.7-0.7,3.5-1,5.3-1c1.1,0,2.2,0.1,3.3,0.2c0.9,0.1,1.8,0.4,2.6,0.7L204.6,383.1z',
      styleClass: 'st1'
    },
    {
      path:
        'M219.5,359.9c-3,0-5.4-2.4-5.4-5.4c0,0,0-0.1,0-0.1c0-1.5,0.5-2.9,1.6-3.8c1-1,2.4-1.5,3.8-1.5	c0.7,0,1.5,0.1,2.1,0.4c0.7,0.2,1.2,0.6,1.8,1.1c0.5,0.5,0.9,1.1,1.2,1.7c0.3,0.7,0.5,1.4,0.4,2.2c0,0.7-0.1,1.5-0.4,2.1	c-0.3,0.7-0.7,1.3-1.2,1.8C222.4,359.4,220.9,359.9,219.5,359.9L219.5,359.9z M215.7,436.3v-61h7.6v61H215.7z',
      styleClass: 'st1'
    },
    {
      path:
        'M250.6,426.5v37.9H243v-89.1h7.6V387h0.2c2.1-4,5.2-7.4,9.1-9.7c7.1-4,15.7-4.5,23.3-1.3c3,1.4,5.7,3.4,7.8,6	c2.2,2.8,3.9,6,4.9,9.4c1.2,4,1.8,8.2,1.7,12.5c0.1,4.7-0.6,9.3-1.9,13.8c-1.1,3.9-3,7.5-5.5,10.7c-2.3,2.9-5.3,5.3-8.7,6.9	c-3.6,1.7-7.5,2.5-11.5,2.5c-8.6,0-15-3.8-19.3-11.3L250.6,426.5z M250.6,411c0,2.7,0.5,5.3,1.4,7.8c0.9,2.4,2.2,4.5,3.9,6.4	c1.7,1.9,3.7,3.4,6,4.4c2.5,1.1,5.1,1.6,7.8,1.6c2.9,0,5.7-0.6,8.3-1.9c2.5-1.3,4.6-3.2,6.3-5.5c1.8-2.6,3.2-5.5,4-8.6	c1-3.7,1.4-7.5,1.4-11.3c0-3.3-0.4-6.5-1.3-9.7c-0.8-2.7-2-5.2-3.7-7.4c-1.6-2-3.6-3.6-5.8-4.6c-2.4-1.1-5-1.6-7.6-1.6	c-3,0-5.9,0.6-8.6,1.8c-2.5,1.1-4.7,2.8-6.5,4.8c-1.8,2.1-3.2,4.5-4.1,7.1c-1,2.8-1.5,5.7-1.4,8.7L250.6,411z',
      styleClass: 'st1'
    },
    {
      path:
        'M360.8,436.3l-12.9-24.5c-1.1-2.1-2.3-4.1-3.7-6.1c-1-1.5-2.3-2.8-3.7-3.9c-1.2-0.9-2.6-1.7-4.1-2.1	c-1.6-0.4-3.2-0.6-4.9-0.6h-9.4v37.2h-7.9v-85.4h24.6c3.2,0,6.3,0.5,9.3,1.4c2.9,0.9,5.6,2.3,8,4.2c2.4,1.9,4.3,4.3,5.6,7	c1.5,3.1,2.2,6.4,2.1,9.8c0,2.8-0.4,5.7-1.4,8.3c-0.9,2.5-2.2,4.8-3.9,6.8c-1.7,2-3.8,3.7-6.1,5c-2.5,1.4-5.3,2.5-8.1,3.1v0.2	c1.1,0.5,2.2,1.1,3.2,1.8c1,0.8,1.9,1.7,2.8,2.7c1,1.2,1.8,2.4,2.6,3.7c0.9,1.4,1.8,3,2.7,4.8l14.4,26.5L360.8,436.3z M322.2,358.2	V392h14.2c2.7,0,5.3-0.4,7.9-1.3c2.3-0.8,4.3-2,6.1-3.6c1.7-1.6,3-3.5,3.9-5.6c1-2.3,1.4-4.9,1.4-7.4c0-5-1.6-8.9-4.8-11.7	s-7.8-4.3-13.8-4.3H322.2z',
      styleClass: 'st1'
    },
    {
      path:
        'M383.6,407.2c0,3.4,0.5,6.8,1.5,10.1c0.8,2.8,2.2,5.3,4.1,7.6c1.8,2.1,4,3.7,6.5,4.7c2.7,1.1,5.7,1.7,8.7,1.6	c6.9,0,13.4-2.4,19.5-7.2v7.5c-5.8,4.2-12.9,6.3-21.2,6.3c-3.8,0.1-7.6-0.7-11.1-2.1c-3.3-1.4-6.2-3.5-8.5-6.2	c-2.5-2.9-4.3-6.3-5.4-10c-1.3-4.4-2-9-1.9-13.6c0-4.3,0.7-8.6,2.1-12.7c1.3-3.7,3.2-7.1,5.8-10.1c2.4-2.8,5.4-5.1,8.8-6.7	c3.5-1.6,7.3-2.4,11.1-2.4c3.6-0.1,7.1,0.7,10.4,2.1c3,1.3,5.6,3.3,7.6,5.9c2.2,2.8,3.8,5.9,4.7,9.3c1.1,4,1.7,8.1,1.6,12.3v3.8	L383.6,407.2z M419.8,400.5c-0.1-6.4-1.6-11.3-4.5-14.8c-2.9-3.5-6.9-5.2-12.1-5.3c-2.5,0-4.9,0.4-7.2,1.4c-2.2,0.9-4.2,2.3-5.8,4	c-1.7,1.8-3.1,4-4.1,6.3c-1.1,2.7-1.9,5.5-2.3,8.4L419.8,400.5z',
      styleClass: 'st1'
    },
    {
      path:
        'M483.2,433.6c-4.5,2.7-10,4.1-16.4,4.2c-4.1,0.1-8.1-0.7-11.8-2.3c-3.4-1.5-6.5-3.7-8.9-6.5	c-2.5-2.8-4.4-6.2-5.7-9.8c-1.4-3.9-2-8.1-2-12.2c0-4.4,0.7-8.7,2.1-12.9c1.3-3.9,3.4-7.5,6.1-10.5c2.7-3,6-5.5,9.8-7.1	c4.2-1.8,8.7-2.7,13.2-2.6c4.8-0.1,9.5,0.9,13.8,2.9v8.1c-4.3-2.9-9.3-4.4-14.4-4.4c-3.3-0.1-6.6,0.6-9.6,2.1	c-2.8,1.3-5.2,3.2-7.1,5.6c-2,2.5-3.5,5.3-4.4,8.3c-1,3.2-1.5,6.6-1.5,10c0,3.2,0.4,6.3,1.3,9.4c0.8,2.9,2.1,5.5,3.9,7.9	c1.8,2.3,4.1,4.2,6.7,5.5c3,1.4,6.2,2.1,9.5,2c5.6,0,11-1.8,15.5-5.1L483.2,433.6z',
      styleClass: 'st1'
    },
    {
      path:
        'M287.5,160.4c-0.4-0.9-0.8-1.6-1.1-2.4s-0.6-0.8-1.3-0.6c-3.8,0.9-7.6,1.8-11.4,2.8c-1.5,0.4-1.6,0.3-1.6-1.3	c0-1.1,0.5-2.1,1.4-2.8c3-2.9,6-5.8,9-8.7c0.8-0.7,1.2-1.7,1-2.8c-0.3-2-0.4-4-0.4-6c0-7.8,0.1-15.6,0.1-23.4c0-1.3-0.3-1.5-1.6-1.3	c-8.3,1.8-16.2,5-23.4,9.4c-3.7,2.3-7.5,4.4-11.2,6.6c-0.8,0.5-1,0.3-1-0.5c0-1.2,0-2.3,0-3.5c0.1-1,0.5-1.8,1.3-2.4	c2.7-2.3,5.3-4.6,8-6.8c0.5-0.3,0.8-0.9,0.7-1.5c0-1.7,0-3.4,0-5.1c0-1,0.4-1.3,1.4-1.3c1.5,0,1.8,0.2,1.9,1.3c0,0.6,0,1.3,0,1.9	c0,0.3,0.2,0.9,0.4,1c0.4,0,0.8-0.1,1.1-0.4c1.9-1.6,3.7-3.2,5.6-4.9c0.9-0.8,2.2-1.5,2.6-2.5s0.1-2.4,0.2-3.7c0-0.7,0-1.4,0-2.1	c0-1,0.4-1.4,1.4-1.4c1.8,0,1.9,0.1,2,1.9c0.1,0.5,0.3,0.9,0.5,1.3c0.4-0.1,0.9-0.3,1.2-0.5c2.8-2.3,5.5-4.7,8.2-7	c0.6-0.5,0.9-1.2,0.8-1.9c0-3.8,0-7.6,0.1-11.4c0.1-4,0.7-8,2-11.8c0.3-0.8,0.6-1.6,1.1-2.3c0.9-1.2,2.1-1.1,2.6,0.3	c0.8,2.5,1.5,5.1,2.1,7.8c1.1,5.2,0.6,10.4,0.6,15.6c0,3.8-0.5,2.6,2.3,5.2c2.2,2,4.4,4,6.7,5.9c0.4,0.2,0.7,0.4,1.1,0.5	c0.2-0.4,0.3-0.8,0.5-1.3c0-0.1,0-0.2,0-0.3c0-1.7,1-2.4,2.6-1.7c0.4,0.3,0.7,0.8,0.7,1.3c0.1,1.6,0,3.3,0,4.9c0,0.5,0.2,1,0.6,1.3	c2.6,2.4,5.3,4.7,8,7c0.3,0.2,0.5,0.3,0.8,0.4c0.1-0.3,0.2-0.6,0.3-0.9c0-0.6,0-1.3,0-1.9c0-1.1,0.4-1.4,1.6-1.4	c1.4,0,1.8,0.3,1.8,1.4c0,1.7,0,3.4,0,5.1c0,0.7,0.3,1.4,0.8,1.8c2.6,2.3,5.2,4.7,7.9,7c0.9,0.7,1.4,1.9,1.4,3.1c0,0.7,0,1.4,0,2.1	c0,0.9-0.4,1.1-1.2,0.6c-1.8-1-3.6-2.2-5.4-3.2c-5-2.8-10-5.7-15.1-8.3c-4.5-2.1-9.3-3.7-14.2-4.7c-0.8-0.2-1.2,0.2-1.3,1	c0,0.3,0,0.7,0,1c0,7.7,0,15.4-0.1,23c-0.1,1.9-0.3,3.7-0.6,5.5c-0.3,1.2,0.1,2.4,1.1,3.2c3,2.5,5.9,5,8.9,7.6	c1.2,0.8,1.8,2.2,1.8,3.6c-0.1,1.2-0.3,1.3-1.4,1.1c-3.2-0.8-6.3-1.5-9.5-2.3c-2.8-0.7-2.3-0.6-3.5,1.6	C288.1,159.5,287.9,159.9,287.5,160.4z',
      styleClass: 'st6'
    },
    {
      path:
        'M236.9,204.5l-19.1,3.5c-0.9-4-2.5-7.4-7.1-7.1s-5.8,3.8-6.7,7.7h-33.5c-1.3-2.3-2.1-4.6-3.6-6.3	c-0.8-0.9-2.6-1.1-4-1.1c-3.5,0-5.2,2.1-6.1,7.4c-4.4,0-8.9,0.1-13.3-0.1c-0.6,0-1.6-1.2-1.6-1.9c-0.4-3.4-1.6-7.4-0.4-10.2	c2-4.5,0.9-8.9,1.3-13.3c0.1-1.7,0.7-2.6,2.4-2.6c0.8,0,1.6-0.1,2.4-0.1c27.2,0,54.5,0,81.7,0c1.4,0,2.9,0.2,4.3,0.6	c2,0.5,3.4,2.3,3.4,4.4C237,191.6,236.9,198,236.9,204.5z M194,186.1h-16.4v7.7H194V186.1z M214,186.1h-16.4v7.7H214V186.1z	 M174,186.1h-16.3v7.6H174L174,186.1z M234.1,193.8c0.1-1.8,0.1-3.7-0.1-5.6c-0.2-0.9-1.3-2.2-2.1-2.3c-4.7-0.2-9.4-0.1-14.1-0.1	v7.9H234.1z M152.5,204.5v-18.4h-4.6v18.4L152.5,204.5z',
      styleClass: 'st7'
    },
    {
      path:
        'M171.7,273.8c3.9-5.2,2.2-4.9,9.8-3.9h0.1c1.1,0,2,0.9,2.1,2c0.4,2,0.8,4,1.1,6c0.1,0.7,0.3,1.2,1.1,1.3	s1.3,0,1.7-0.8c1-1.9,2.1-3.7,3.1-5.6c0.3-0.8,1.2-1.2,2-1c1.8,0.2,3.6,0.5,5.4,0.6c1,0,1.8,0.7,1.9,1.7c0.5,1.9,1,3.8,1.4,5.7	c0.2,0.7,0.3,1.3,1.2,1.4c0.7,0.2,1.5-0.2,1.8-0.9c0.8-1.8,1.8-3.5,2.8-5.1c0.4-0.7,1-1.1,1.7-1.3c2.2-0.1,4.4,0.1,6.6,0.3	c0.9,0,1.7,0.7,1.9,1.6c0.4,1.5,0.9,3,1.3,4.5c0.8,2.9,0.8,3,3.9,3.3c1,0,1.8,0.7,1.9,1.6c0.5,3.1,1.1,6.3,1.6,9.4	c0.1,0.8,0.3,1.2,1.1,1.2c1.4,0,1.8,1,1.6,2.1c-0.3,1.7-0.9,3.4-1.3,5.1c-0.4,1.5-1.3,2-2.9,2c-28.4-0.1-56.9-0.1-85.3,0	c-3.1,0-3.2,0-3.2-3.1c0-2.8-0.9-5.6-2.6-7.8c-2.4-3.3-4.7-6.7-7-10.2c-1.3-2.1-1-2.7,1.5-2.6c3.3,0,6.7,0.2,10,0.4	c0.9,0.2,1.8-0.3,2.2-1.1c1.6-2.6,3.3-5.1,4.9-7.8c0.5-1.1,1.7-1.7,2.8-1.5c5.9,0.7,11.8,1.3,17.6,2c1.8,0.2,3.9,0.1,4.4,2.9	C170.9,274.9,171.3,274.4,171.7,273.8z M220.7,284.8c-6.9-0.9-13.9-1.9-20.8-2.7c-12.5-1.6-25-3.2-37.5-4.7	c-5.3-0.7-10.6-1.3-15.9-1.9c-1.3-0.2-2,0.4-2.5,1.8c26.2,3.7,52.4,6.6,78.6,9.8c0.1-1.1-0.7-2.1-1.8-2.2	C220.8,284.8,220.8,284.8,220.7,284.8L220.7,284.8z M221.4,288.4c-5-0.7-10-1.3-15-1.9l-57.7-7.1c-1.4-0.2-2.9-0.3-4.3-0.5	c-1.3-0.1-1.3-0.1-1.7,1.4l80.7,9.7C223.3,288.3,222.2,288.5,221.4,288.4L221.4,288.4z M222.3,291.1c-12.8-1.4-25.7-2.9-38.5-4.4	l-21.4-2.4c-0.4,0-0.9,0-1.4-0.1c-0.1,1.1,0.2,1.6,1.4,1.8c16.3,1.8,32.7,3.7,49,5.5l11,1.3c0.4,0,0.8,0,1.3,0.1	C223.9,291.6,223.4,291.2,222.3,291.1L222.3,291.1z M162.1,287.5c-0.1,1.4,0.7,1.6,1.6,1.7c5.4,0.5,10.9,1.1,16.3,1.6	c12,1.1,24,2.2,36.1,3.3c1.9,0.2,3.9,0.3,5.8,0.4v-0.5L162.1,287.5z M168.7,276.7c0.2-1.4-0.2-2.1-1.4-2.3c-6.5-0.7-13-1.3-19.4-1.9	c-0.7-0.1-1.4,0.6-2.2,0.9l0.1,0.6L168.7,276.7z',
      styleClass: 'st10'
    },
    {
      path:
        'M222.7,287c0.1-1.1-0.7-2.1-1.8-2.2c-0.1,0-0.1,0-0.2,0c-6.9-0.9-13.9-1.9-20.8-2.7c-12.5-1.6-25-3.2-37.5-4.7	c-5.3-0.7-10.6-1.3-15.9-1.9c-1.3-0.2-2,0.4-2.5,1.8C170.3,280.9,196.5,283.8,222.7,287z',
      styleClass: 'st8'
    },
    {
      path:
        'M223.5,290c-0.2-1.6-1.2-1.4-2-1.5c-5-0.7-10-1.3-15-1.9l-57.7-7.1c-1.4-0.2-2.9-0.3-4.3-0.5	c-1.3-0.1-1.3-0.1-1.7,1.4L223.5,290z',
      styleClass: 'st8'
    },
    {
      path:
        'M223.7,292.8c0.1-1.2-0.3-1.7-1.4-1.8c-12.8-1.4-25.7-2.9-38.5-4.4l-21.4-2.4c-0.4,0-0.9,0-1.4-0.1	c-0.1,1.1,0.2,1.6,1.4,1.8c16.3,1.8,32.7,3.7,49,5.5l11,1.3C222.8,292.8,223.2,292.8,223.7,292.8z',
      styleClass: 'st8'
    },
    {
      path:
        'M221.8,294.1l-59.7-6.6c-0.1,1.4,0.7,1.6,1.6,1.7c5.4,0.5,10.9,1.1,16.3,1.6c12,1.1,24,2.2,36.1,3.3	c1.9,0.2,3.9,0.3,5.8,0.4L221.8,294.1z',
      styleClass: 'st8'
    },
    {
      path:
        'M145.8,274l22.9,2.6c0.2-1.4-0.2-2.1-1.4-2.3c-6.5-0.7-13-1.3-19.4-1.9c-0.7-0.1-1.4,0.6-2.2,0.9L145.8,274z',
      styleClass: 'st8'
    },
    {
      path:
        'M377.3,175c0,0-0.6,0.3-0.6,0.3s-0.4,1.3-0.4,1.3c-0.9,1.6-1.6,3.3-2.2,5c-0.3,1.9-0.4,3.9-0.2,5.9	c0.2,1.5,0.8,2.9,1.6,4.2l8.5,1.5c-0.4-1.6-0.5-3.2-0.4-4.9c0.2-2.5,2.3-6.2,5.7-6.8s6.5-0.5,8.9,1.9s2.9,5,2.5,10.2h43	c-0.3-2.2-0.3-4.5,0.2-6.7c0.8-3.5,4.6-5.7,7.6-5.7s5.9,0.8,7.6,3s2.1,5.3,1.3,9.6l9.3-0.7v-2c0,0-0.9-0.7-0.9-1.3s0.3-4.9,0.3-4.9	l-1.2-2.6c-1.6-1.5-3.6-2.5-5.7-3.1c-3.6-1.1-7.3-1.9-11.1-2.4c-4.3-0.6-8.3-1-8.3-1l-2.8,0.2c0,0-9.1-5.4-13.8-7.1	c-2.1-0.6-4.3-1-6.5-1.2c-5.7-0.1-11.5,0.4-17.1,1.4c0,0-6.4,1.6-8.8,2.3s-5.9,2.3-5.9,2.3h-0.7c-1.1,0.3-2.2,0.5-3.3,0.6l-2-1.5	l-5.7-0.2c0,0-0.2,0.5,0.6,0.7c0,0,0.9,0,1,0.6C377.7,174.5,377.6,174.8,377.3,175z',
      styleClass: 'st11'
    },
    {
      path:
        'M399.3,175.3c-0.3,0-0.5-0.2-0.5-0.5c0-0.1,0.1-0.2,0.1-0.3c0.7-0.9,1.5-1.7,2.4-2.4c2.4-0.7,4.8-1.2,7.2-1.6	c1.9-0.2,12.8-0.9,12.8-0.9s8.1,3.4,12.9,7.2L399.3,175.3z',
      styleClass: 'st8'
    },
    {
      path: 'M423.9,169.3l12.6,7.3l3-0.2c0,0-8.4-5.1-13.7-7.1L423.9,169.3z',
      styleClass: 'st8'
    },
    {
      path:
        'M427.7,173.8c0,0-1,0.6-1,0.8v1.9c0,0,0.1,0.3,0.5,0.3s1.4,0.1,1.4,0.1c-0.2,0.3-0.3,0.6-0.5,0.9	c0.1,0.3,0.3,0.6,0.7,0.6c0.5,0.1,1,0.1,1.5,0c0.2-0.1,0.4-0.3,0.5-0.5c0-0.1-0.5-1.1-0.5-1.1l0.2-0.2V175	C429.9,174.2,428.8,173.8,427.7,173.8z',
      styleClass: 'st11'
    },
    {
      path:
        'M375.9,180.2c0,0-0.3-0.1-0.1-0.8s1.3-3.1,2.1-3.1s-0.8,2.7-0.8,2.7S376.4,180.4,375.9,180.2z',
      styleClass: 'st8'
    },
    {
      path:
        'M436.4,180l4.1,0.4c0.4,0,0.6,0.4,0.6,0.8c0,0.1,0,0.2-0.1,0.2c-1.3,2.3-4.8,8.1-6,8.5c-1.4,0.5-23.2,0-23.2,0	s22.3-0.6,22.8-0.8s5.4-7.8,5.4-7.8L436.4,180z',
      styleClass: 'st8'
    },
    {
      path:
        'M455,179.1c0,0,6.8,1.7,9.4,4.2c1,0.3,2,0.5,3,0.7c-0.4-0.9-1-1.7-1.9-2.1c-1.6-1.1-7.8-2.6-7.8-2.6L455,179.1z	',
      styleClass: 'st8'
    },
    {
      path:
        'M422.2,292.8v0.2h-51c-5.4,0-10.2-3.6-11.7-8.9h52.8C413.6,288.6,417.5,292,422.2,292.8L422.2,292.8z	 M359.5,282.8c-0.3-1.1-0.5-2.2-0.5-3.4v-2.8c0-6.7,5.5-12.2,12.2-12.2h51v0.1c-6,0.9-10.4,6-10.4,12.1v2.7c0,1.1,0.2,2.3,0.5,3.4	L359.5,282.8z M399,273.7c0,1.9,1.5,3.4,3.4,3.4s3.4-1.5,3.4-3.4s-1.5-3.4-3.4-3.4h0C400.5,270.3,399,271.8,399,273.7z M387.5,273.7	c0,1.9,1.5,3.4,3.4,3.4s3.4-1.5,3.4-3.4s-1.5-3.4-3.4-3.4h0C389,270.3,387.5,271.8,387.5,273.7z M376,273.7c0,1.9,1.5,3.4,3.4,3.4	s3.4-1.5,3.4-3.4s-1.5-3.4-3.4-3.4h0C377.5,270.3,376,271.8,376,273.7z M364.5,273.7c0,1.9,1.5,3.4,3.4,3.4s3.4-1.5,3.4-3.4	s-1.5-3.4-3.4-3.4h0C366,270.3,364.5,271.8,364.5,273.7z M425.9,284.1H416c1.3,4.6,5.2,8,9.9,8.7c0.6,0.1,1.2,0.2,1.8,0.2h50.1v-8.9	L425.9,284.1z M425.9,264.7c0.6-0.1,1.2-0.1,1.8-0.1h26.6c2.7,0,5.2,0.9,7.4,2.5c-8.7,0.4-15.3,2.1-15.3,4.2c0,2.4,8.4,4.3,18.8,4.3	c2.7,0,5.3-0.1,7.6-0.4c1.6,1,3.3,1.8,5.1,2.4v5.3H416c-0.3-1.1-0.5-2.2-0.5-3.4v-2.8C415.5,270.7,420,265.6,425.9,264.7	L425.9,264.7z M432,273.7c0,1.9,1.5,3.4,3.4,3.4s3.4-1.5,3.4-3.4s-1.5-3.4-3.4-3.4h0C433.5,270.3,432,271.8,432,273.7z M420.7,273.7	c-0.1,1.9,1.3,3.4,3.2,3.5c1.9,0.1,3.4-1.3,3.5-3.2c0-0.1,0-0.2,0-0.4c0-1.9-1.5-3.4-3.4-3.4C422.2,270.3,420.7,271.8,420.7,273.7	C420.7,273.6,420.7,273.7,420.7,273.7z M488.4,284H479v8.9c3.2-0.1,6.3-1.4,8.6-3.6C489.1,287.9,489.5,285.7,488.4,284L488.4,284z	 M481,278c-0.7-0.1-1.3-0.3-2-0.5v5.3h9.4C486.8,280.2,484.1,278.4,481,278z',
      styleClass: 'st12'
    },
    {
      path:
        'M102.5,333.6h395c6.6,0,12,5.4,12,12v117c0,6.6-5.4,12-12,12h-395c-6.6,0-12-5.4-12-12v-117	C90.5,338.9,95.9,333.6,102.5,333.6z',
      styleClass: 'st4'
    }
  ],
  lines: [
    {
      styleClass: 'st4',
      coords: {
        x1: '287.5',
        y1: '163.3',
        x2: '287.5',
        y2: '333.3'
      }
    }
  ],
  polylines: [
    {
      styleClass: 'st0',
      points: '274.5,333.8 274.5,306.8 135.5,306.8 '
    },
    {
      styleClass: 'st2',
      points: '135.5,213.8 241.5,213.8 241.5,261.8 280.5,261.8 280.5,331.8 '
    },
    {
      styleClass: 'st3',
      points: '470.5,199.8 369.5,199.8 369.5,227.8 294.5,227.8 294.5,333.8 '
    },
    {
      styleClass: 'st5',
      points: '301.5,331.8 301.5,298.8 491.5,298.8 '
    }
  ],
  polygons: [
    {
      styleClass: 'st11',
      points: '408.2,170.4 409.2,175.7 410,175.7 408.5,170.4 '
    }
  ],
  circles: [
    {
      styleClass: 'st7',
      coords: {
        cx: '211',
        cy: '207.3',
        r: '5'
      }
    },
    {
      styleClass: 'st8',
      coords: {
        cx: '211',
        cy: '207.3',
        r: '1.9'
      }
    },
    {
      styleClass: 'st9',
      coords: {
        cx: '163',
        cy: '207.3',
        r: '5'
      }
    },
    {
      styleClass: 'st8',
      coords: {
        cx: '163',
        cy: '207.3',
        r: '1.9'
      }
    },
    {
      styleClass: 'st11',
      coords: {
        cx: '392.2',
        cy: '189.9',
        r: '7.4'
      }
    },
    {
      styleClass: 'st8',
      coords: {
        cx: '392.2',
        cy: '189.9',
        r: '5.7'
      }
    },
    {
      styleClass: 'st11',
      coords: {
        cx: '451.9',
        cy: '189.9',
        r: '7.4'
      }
    },
    {
      styleClass: 'st8',
      coords: {
        cx: '451.9',
        cy: '189.9',
        r: '5.7'
      }
    },
    {
      styleClass: 'st13',
      coords: {
        cx: '300',
        cy: '300',
        r: '290'
      }
    }
  ],
  rectangles: [],
  ellipses: [],
  style: {
    st0: {
      fill: 'none',
      stroke: '#80A1C1',
      stokeWidth: '2',
      'stroke-miterlimit': '10'
    },
    st1: {
      fill: '#6D4C41',
      stroke: '#6D4C41',
      stokeWidth: '5',
      'stroke-miterlimit': '10'
    },
    st2: {
      fill: 'none',
      stroke: '#F58C73',
      stokeWidth: '2',
      'stroke-miterlimit': '10'
    },
    st3: {
      fill: 'none',
      stroke: '#D39A47',
      stokeWidth: '2',
      'stroke-miterlimit': '10'
    },
    st4: {
      fill: 'none',
      stroke: '#00C853',
      stokeWidth: '3',
      'stroke-miterlimit': '10'
    },
    st5: {
      fill: 'none',
      stroke: '#83CDA1',
      stokeWidth: '2',
      'stroke-miterlimit': '10'
    },
    st6: {
      fill: '#00C853',
      stroke: '#00C853',
      'stroke-miterlimit': '10'
    },
    st7: {
      fill: '#F58C73'
    },
    st8: {
      fill: '#FFFFFF'
    },
    st9: {
      fill: '#F58C73',
      stroke: '#FFFFFF',
      'stroke-miterlimit': '10'
    },
    st10: {
      fill: '#80A1C1'
    },
    st11: {
      fill: '#D39A47'
    },
    st12: {
      fill: '#83CDA1'
    },
    st13: {
      fill: 'none',
      stroke: '#6D4C41',
      stokeWidth: '15',
      'stroke-miterlimit': '10'
    }
  }
};
