import React, { Component } from 'react';

class LargeCardBox extends Component  {
  constructor(props) {
    super(props);
  }


render(){
  const {
    children,
    title,
    size,
    secondaryText,
    className,
    customSize,
    style,
    padding,
    receipt,
    margin,
    blue
  } = this.props;
  const row = size === 12 ? '' : 'col-sm-12 col-xs-12';

  return (
    <div
      className = {
        customSize
          ? `${customSize} ${className !== undefined ? className : ''}`
          : `col-md-${size} ${row} ${className !== undefined ? className : ''}`
      }
      style={style}
    >
      <div
        className={`card-box ${blue && 'blue'} ${
          receipt !== undefined ? receipt : ''
        }`}
        style={{ padding, margin }}
      >
        {title && (
          <h2 className={`text-dark header-title m-t-0 ${!secondaryText}`}>
            {title}
          </h2>
        )}
        {children}
        {secondaryText && <p className='text-muted font-13'>{secondaryText}</p>}
        <div className='clearfix' />
      </div>
    </div>
  );
  }
}

LargeCardBox.defaultProps = {
  size: 6
};

export default LargeCardBox;