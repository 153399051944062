import React, { Component } from 'react';
import Main from './mainRoutes';
import Navigation from './pages/Navigation';

export class AppLayout extends Component {
  render() {
    const newLocal = this.props;
    const { history } = newLocal;

    return (
      <div className="ui">
        <Navigation history={history} />
        <div className="content">
          <Main />
        </div>
      </div>
    );
  }
}

export default AppLayout;
