import React, { Component } from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import styled from 'react-emotion';

import Token from '../../components/token';
import SVG from '../../components/svg';

const FormLogin = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
  margin: auto;
  padding: 30px 30px 50px;
`;
const CreateBox = styled('div')`
  margin-top: 15px;
  cursor: pointer;
`;
const FormField = styled('div')`
  text-align: center;
  margin-bottom: 10px;
`;
const InfoBox = styled('div')`
  margin-bottom: 20px;
`;

export class Login extends Component {
  state = {
    login: true,
    email: '',
    password: '',
    firstName: '',
    lastName: ''
  };

  render() {
    const SIGNUP_MUTATION = gql`
      mutation(
        $email: String!
        $password: String!
        $firstName: String!
        $lastName: String!
      ) {
        Signup(
          email: $email
          password: $password
          firstName: $firstName
          lastName: $lastName
        )
      }
    `;
    const LOGIN_MUTATION = gql`
      mutation($email: String!, $password: String!) {
        Login(email: $email, password: $password)
      }
    `;

    const { login, email, password, firstName, lastName } = this.state;

    return (
      <FormLogin>
        <SVG name="CircleLogo" width={200} height={200} />

        <InfoBox>
          <h2 className="text-center">
            {login ? 'Login To TripRec' : 'Sign Up For TripRec'}
          </h2>

          {!login && (
            <div>
              <FormField>
                <FormControl className="Block form-default">
                  <InputLabel htmlFor="firstName">First Name</InputLabel>
                  <Input
                    id="firstName"
                    value={firstName}
                    type="text"
                    fullWidth={true}
                    onChange={e => this.setState({ firstName: e.target.value })}
                    label="firstName"
                  />
                </FormControl>
              </FormField>
              <FormField>
                <FormControl className="Block">
                  <InputLabel htmlFor="lastName">Last Name</InputLabel>
                  <Input
                    id="lastName"
                    value={lastName}
                    type="text"
                    fullWidth={true}
                    onChange={e => this.setState({ lastName: e.target.value })}
                    label="lastName"
                  />
                </FormControl>
              </FormField>
            </div>
          )}
          <FormField>
            <FormControl className="block form-default">
              <InputLabel htmlFor="email">E-Mail</InputLabel>
              <Input
                id="email"
                value={email}
                type="text"
                fullWidth={true}
                onChange={e => this.setState({ email: e.target.value })}
                label="email"
              />
            </FormControl>
          </FormField>
          <FormField>
            <FormControl className="block form-default">
              <InputLabel htmlFor="password">Password</InputLabel>
              <Input
                fullWidth={true}
                id="password"
                value={password}
                type="password"
                onChange={e => this.setState({ password: e.target.value })}
                label="Username"
              />
            </FormControl>
          </FormField>
        </InfoBox>

        <Mutation
          mutation={login ? LOGIN_MUTATION : SIGNUP_MUTATION}
          variables={{ email, password, firstName, lastName }}
          onCompleted={data => this._confirm(data)}
        >
          {mutation => (
            <Button
              variant="contained"
              size="small"
              onClick={mutation}
              color="primary"
            >
              {login ? 'login' : 'create account'}
            </Button>
          )}
        </Mutation>

        <CreateBox onClick={() => this.setState({ login: !login })}>
          {login ? 'need to create an account?' : 'already have an account?'}
        </CreateBox>
      </FormLogin>
    );
  }

  _confirm = async data => {
    const Login = this.state.login ? data.Login : data.Signup;
    this._saveUserData(Login);
    this.props.history.push('/dashboard');
  };

  _saveUserData = token => {
    console.log(`Token is ${token}`);
    Token.set(token);
  };
}

export default Login;
