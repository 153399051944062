import React, { Component } from 'react';
import svgImages from './images';

export class Icon extends Component {
  render() {
    const { name = '', width = 35, height = 35, fill } = this.props;
    if (!svgImages.hasOwnProperty(name)) {
      return null;
    }
    const {
      paths,
      rectangles,
      polygons,
      circles,
      lines,
      polylines,
      style = {}
    } = svgImages[name];
    const stroke = fill
      ? {
          stroke: fill
        }
      : null;

    return svgImages.hasOwnProperty(name) ? (
      <div>
        <svg width={width} height={height} viewBox={'0 0 600 600'}>
          {paths &&
            paths.map(({ styleClass, path }, index) => (
              <path
                style={{ ...style[styleClass], ...stroke }}
                fill={style[styleClass] && style[styleClass].fill ? '' : fill}
                d={path}
                key={index}
              />
            ))}

          {rectangles &&
            rectangles.map(({ styleClass, ...coords }, index) => (
              <rect
                style={{ ...style[styleClass], ...stroke }}
                fill={style[styleClass] && style[styleClass].fill ? '' : fill}
                {...coords}
                key={index}
              />
            ))}

          {polygons &&
            polygons.map(({ styleClass, points }, index) => (
              <polygon
                style={{ ...style[styleClass], ...stroke }}
                fill={style[styleClass] && style[styleClass].fill ? '' : fill}
                points={points}
                key={index}
              />
            ))}
          {polylines &&
            polylines.map(({ styleClass, ...points }, index) => (
              <polyline
                style={{ ...style[styleClass], ...stroke }}
                fill={style[styleClass] && style[styleClass].fill ? '' : fill}
                {...points}
                key={index}
              />
            ))}

          {lines &&
            lines.map(({ styleClass, coords }, index) => (
              <line
                style={{ ...style[styleClass], ...stroke }}
                fill={style[styleClass] && style[styleClass].fill ? '' : fill}
                {...coords}
                key={index}
              />
            ))}

          {circles.length > 0 &&
            circles.map(({ styleClass, coords }, index) => (
              <circle
                style={{ ...style[styleClass], ...stroke }}
                fill={style[styleClass] && style[styleClass].fill ? '' : fill}
                {...coords}
                key={index}
              />
            ))}
        </svg>
      </div>
    ) : null;
  }
}

export default Icon;
