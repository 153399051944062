import React, { Component } from 'react';

class stop extends Component {
  render() {
    const {
      stop: {
        location: { name }      }
    } = this.props;
    return <div>{name}</div>;
  }
}

export default stop;
